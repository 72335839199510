import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import SideBarc from '../components/SideBarc'
import './Portifolio.css'
import { useApi } from '../hooks/use-api.hook'
import { useUser } from '../hooks/use-user.hook'
import 'bootstrap'
import { useRef, useState } from 'react'
import { Modal } from 'bootstrap'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { BsInfoCircle } from 'react-icons/bs'
import PortfolioModal from './PortifolioModal'


const Portifolio = () => {
  const navigate = useNavigate()

  const user = useUser()

  const { data, isLoading } = useApi(
    '/transactions.json?page=1&per_page=20',
    'portifolio',
    Boolean(user?.token)
  )

  const [showModal, setShowModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  // Handle opening the modal
  const handleOpenModal = (item) => {
    setSelectedItem(item)
    setShowModal(true)
  }

  // Handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false)
    setSelectedItem(null)
    console.log('close')
  }

  // making the date more readable

  //
  const STATUS = {
    CREATED: 'created',
    PROCESSING: 'processing',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
    TO_SELL: 'to_sell',
    SOLD: 'sold',
    CLAIMED: 'claimed'
  }
  const getStatusText = (status) => {
    switch (status) {
      case STATUS.CREATED:
        return 'Awaiting payment'
      case STATUS.PROCESSING:
        return 'Processing'
      case STATUS.SUCCEEDED:
        return 'Succeeded'
      case STATUS.TO_SELL:
        return 'In sell process'
      case STATUS.SOLD:
        return 'Sold'
      case STATUS.CLAIMED:
        return 'Claimed'

      case STATUS.FAILED:
        return 'Failed'
      default:
        return ''
    }
    
  }

  const shouldShowPayNowButton = (status) => status === STATUS.CREATED
  const sellButton = (status) => status === STATUS.SUCCEEDED

  const statusBadge = ({ item }) => {
    const statusText = getStatusText(item.status)
    console.log(item.status, statusText)

    return (
      <div>
        <div className="badgeAlingToTheRight">
          <span className={`badge status ${item.status.toLowerCase()}`}>
            <i className="bi bi-check-circle pr-8"></i>
            {statusText}
          </span>
          {shouldShowPayNowButton(item.status) && (
            <Link to={`/payments/${item.id}`} className="btn btn-primary">
              Pay Now
            </Link>
          )}
          <div className="padding-buttons"></div>
          {sellButton(item.status) && (
            <span onClick={() => handleOpenModal(item)} className="btn btn-primary ds-block">
              Sell token
            </span>
          )}
        </div>
      </div>
    )
  }

  const searchInputRef = useRef(null)

  const toggleSearchInput = (event) => {
    event.target.style.display = ' none'
    const searchInput = searchInputRef.current
    if (searchInput.style.display === 'none' || searchInput.style.display === '') {
      searchInput.style.display = 'block'
      searchInput.focus()
    } else {
      searchInput.style.display = 'none'
    }
  }

  useEffect(() => {
    if (!user) {
      navigate('/login?re=portfolio')
    }
  }, [user, navigate])

  return (
    <>
      <SideBarc />
      <div className="container">
        <header className="d-flex justify-content-between align-items-center MyPortfoliHeader">
          <h3 className="mb-4">My portfolio</h3>
          <div>
            <span className="input-group" onClick={(e) => toggleSearchInput(e)}>
              <i className="bi bi-search"></i>
            </span>
            <div className="input-group" ref={searchInputRef} style={{ display: 'none' }}>
              <span className="input-group-text bg-transparent pb-0 pt-0">
                <i className="bi bi-search"></i>
                <input
                  type="text"
                  className="form-control search-input border-0 shadow-none"
                  placeholder="Search"
                />
              </span>
            </div>
          </div>
        </header>
        <div
          className="accordion"
          id="portfolioAccordion"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
          aria-expanded="true"
          aria-controls="collapseOne"
        >
          {}
        </div>

        {isLoading ?? <p>loading...</p>}
        {data ? (
          data.map((item) => (
            <>
              <div className="mb-3">
                <div className="EachProduct mb-4" key={item.id}>
                  <div className="PandingNextToTokenName">
                    <h5 className="ProdcutName">{item.token.tokenname}</h5>
                    {statusBadge({ item })}
                  </div>
                  {(item.status === 'succeeded' || item.status === 'to_sell') &&
                    item.generate_token !== '' && (
                      <div>
                        <p className="MyPortifolioinfo">
                          {' '}
                          <p className="text-muted info">
                            Token:{' '}
                            <b>
                              {' '}
                              <a
                                href={`https://apothem.xdcscan.io/block/${item.generate_token}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.generate_token}
                              </a>
                            </b>
                          </p>
                        </p>
                      </div>
                    )}
                  {item.status === 'succeeded' && item.generate_token == '' && (
                    <p className="MyPortifolioinfo">
                      {' '}
                      <p className="text-muted info">
                        Token: <b>Generating</b>
                      </p>
                    </p>
                  )}
                  <div className="myPortifolioInformation01 ">
                    <div className="myPortifolioInformation02 ">
                      <p className="text-muted info">
                        Strategy <b>{item.token.strategy}</b>
                      </p>
                      <p className="text-muted info">
                        Domicile <b>{item.token.domicile}</b>
                      </p>
                      <p className="text-muted info">
                        Seller name <b>{item.token.sellername}</b>
                      </p>
                    </div>
                    <div className="myPortifolioInformation02 ">
                      <p className="text-muted info">
                        Share Price <b>${item.token.shareprice}</b>
                      </p>
                      <p className="text-muted info">
                        Date of Purchase <b>{item.user.created_at.split('T')[0]}</b>
                      </p>
                      <p className="text-muted info">
                        {' '}
                        Redemption deadline <b>{item.token.redemptiondeadline}</b>
                      </p>
                    </div>
                    <div className="myPortifolioInformation03 ">
                      <p className="MyPortifolioinfo">
                        {' '}
                        Total Amount Invested{' '}
                        <b>
                          {' '}
                          $
                          {Intl.NumberFormat('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }).format(item.total_amount)}{' '}
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))
        ) : (
          <p className="EachProduct">No data available</p>
        )}
        {/* Bootstrap Modal */}
      </div>
      <PortfolioModal
        selectedItem={selectedItem}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        user={user}
      />
    </>
  )
}

export default Portifolio
