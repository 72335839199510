import './PageNotFound.css';
import background from '../assets/img/Speck_full_logo.svg';

const PageNotFound = () => {




  return (
    <>
<div className='body404notfound'>

<img className='mainlogo4040notfound' src={background} alt="" />
<div className='textsnotfound404'>
<div className='text404notfound'>404</div>
<div className='pagenotfound'> Page not found</div>
</div>
<a href="/"><button className="error__button error__button--active">Go Back to Home</button></a>




</div>

    </>
  );
};

export default PageNotFound;  