import React, { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import emailjs from 'emailjs-com'
import './GetEarlyAccessButton.css'

const GetEarlyAcessButton = () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [showModal, setShowModal] = useState(false)

  emailjs.init('service_7mds8y9')

  const handleFormSubmit = () => {
    const templateParams = {
      from_name: email,
      to_name: 'support@speck.finance',
      message_html: message
    }

    emailjs.send('service_7mds8y9', 'template_x36r976', templateParams, '9GMpW727ZaPZNKwQg').then(
      (response) => {
        console.log('SUCCESS!', response.status, response.text)
      },
      (err) => {
        console.log('FAILED...', err)
      }
    )

    setShowModal(false)
  }

  const handleClick = () => {
    setShowModal(true)
  }

  return (
    <div>
      <button className="pill-button" onClick={handleClick}>
        Get Early Access
      </button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Get Early Access</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="EarlyAcessEmailForm">
            <Form.Group controlId="email">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="message">
              <Form.Label>Message:</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="pill-button" onClick={handleFormSubmit}>
            Send
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default GetEarlyAcessButton

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////// SIMPLE BUTTON WHERE THE CLIENT MUST HAVE AN EMAIL SENDER SET UP ON THEIR COMPUTER //////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// import React from 'react'

// const GetEarlyAcessButton = () => {
//   const handleClick = () => {
//     const subject = 'Get Early Access'
//     const body = 'Hello, I want to get early access.'
//     const mailto = `mailto:suport@speck.finance?subject=${subject}&body=${body}`
//     window.location.href = mailto
//   }

//   return (
//     <button className="pill-button" onClick={handleClick}>
//       Get Early AcessButton
//     </button>
//   )
// }

// export default GetEarlyAcessButton

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////// SIMPLE BUTTON WHERE THE CLIENT MUST HAVE AN EMAIL SENDER SET UP ON THEIR COMPUTER //////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// import React, { useState } from 'react';
// import { Modal, Button, Form } from 'react-bootstrap';
// import AWS from 'aws-sdk';
// import './GetEarlyAccessButton.css'

// const GetEarlyAcessButton = () => {
//   const [email, setEmail] = useState('');
//   const [message, setMessage] = useState('');
//   const [showModal, setShowModal] = useState(false);

//   const handleFormSubmit = () => {
//     AWS.config.update({
//       region: 'us-east-1',
//       accessKeyId: 'AKIA3BEQT7G7D77ZGKXL',
//       secretAccessKey: '6jdh4t/nCe39x9f8Eeu58S29OTmaz5zWob41avSX',
//     });

//     const ses = new AWS.SES({ apiVersion: '2010-12-01' });

//     const params = {
//       Source: 'your-email-address@example.com',
//       Destination: {
//         ToAddresses: ['support@speck.finance'],
//         CcAddresses: ['cc-email-address@example.com'],
//         BccAddresses: ['bcc-email-address@example.com'],
//       },
//       Message: {
//         Body: {
//           Text: {
//             Data: message,
//           },
//         },
//         Subject: {
//           Data: 'Get Early Access Request from ' + email,
//         },
//       },
//     };

//     ses.sendEmail(params, (err, data) => {
//       if (err) {
//         console.log(err);
//       } else {
//         console.log(data);
//       }
//     });

//     setShowModal(false);
//   };

//   const handleClick = () => {
//     setShowModal(true);
//   };

//   return (
//     <div>
//         <button className="pill-button" onClick={handleClick}>
//             Get Early AcessButton
//         </button>

//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Get Early Access</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form className='EarlyAcessEmailForm'>
//             <Form.Group controlId="email">
//               <Form.Label>Email:</Form.Label>
//               <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
//             </Form.Group>
//             <Form.Group controlId="message">
//               <Form.Label>Message:</Form.Label>
//               <Form.Control as="textarea" value={message} onChange={(e) => setMessage(e.target.value)} />
//             </Form.Group>
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
// <button className="pill-button" onClick={handleFormSubmit}>
//     Send
// </button>
//           {/* <Button variant="primary" onClick={handleFormSubmit}>
//             Send
//           </Button> */}
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default GetEarlyAcessButton;

////////////////////////////////
/////email js///////////////////
////////////////////////////////




/////////////////////////////////////////
///////this code actualy works///////////
/////////////////////////////////////////
// import React, { useRef } from 'react';
// import emailjs from '@emailjs/browser';
// import { Modal, Button, Form } from 'react-bootstrap'

// export const GetEarlyAcessButton = () => {
//   const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs
//       .sendForm('service_7mds8y9', 'template_x36r976', form.current, {
//         publicKey: '9GMpW727ZaPZNKwQg',
//       })
//       .then(
//         () => {
//           console.log('SUCCESS!');
//         },
//         (error) => {
//           console.log('FAILED...', error.text);
//         },
//       );
//   };

//   return (
// <>
//     <div>teste</div>
//     <form ref={form} onSubmit={sendEmail}>
//       <label>Name</label>
//       <input type="text" name="name" />
//       <label>Email</label>
//       <input type="email" name="email" />
//       <label>Message</label>
//       <textarea name="message" />
//       <input type="submit" value="Send" />
//     </form>
// </>
//   );
// };

// export default GetEarlyAcessButton

/////////////////////////////////////
////////// trying with boostrap//////
/////////////////////////////////////

// import React, { useRef, useState } from 'react'
// import emailjs from '@emailjs/browser'

// export const GetEarlyAcessButton = () => {
//   const form = useRef()

//   const sendEmail = (e) => {
//     e.preventDefault()

//     emailjs
//       .sendForm('service_7mds8y9', 'template_x36r976', form.current, {
//         publicKey: '9GMpW727ZaPZNKwQg'
//       })
//       .then(
//         () => {
//           console.log('SUCCESS!')
//           setShowModal(false) // Close the modal after sending the email
//         },
//         (error) => {
//           console.log('FAILED...', error.text)
//         }
//       )
//   }

//   const [showModal, setShowModal] = useState(false)

//   const handleClick = () => {
//     setShowModal(true)
//   }

//   return (
//     <>
//       <button onClick={handleClick}>Get Early Access</button>

//       {showModal && (
//         <div className="modal">
//           <div className="modal-content">
//             <span className="close" onClick={() => setShowModal(false)}>
//               &times;
//             </span>
//             <h2>Get Early Access</h2>
//             <form ref={form} onSubmit={sendEmail}>
//               <label>
//                 Name:
//                 <input type="text" name="user_name" />
//               </label>
//               <label>
//                 Email:
//                 <input type="email" name="email" />
//               </label>
//               <label>
//                 Message:
//                 <textarea name="message" />
//               </label>
//               <button type="submit">Send</button>
//             </form>
//           </div>
//         </div>
//       )}
//     </>
//   )
// }

// export default GetEarlyAcessButton
