import React from 'react'
import './Fill-KYC-button.css'
import { useNavigate } from 'react-router-dom'

const FillKYCButton = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/KYC')
  }

  return (
    <button className="KYC-fill-pill-button" onClick={handleClick}>
      Complete your Registration 
    </button>
  )
}

export default FillKYCButton
