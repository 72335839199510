import React, { useState, useEffect } from 'react'
import CardLP from '../components/LP-cards'
import { SpeckNavBar } from '../components/NavBar'
import './Home.css'
import FooterComponent from '../components/Footer'
import BuyTokensButton from '../components/Buy-tokens-button'
import MainText from '../components/Text-Main'
import PrimaryOfferings from '../components/Primary-offerings'
import UnveilingOpportunites from '../components/UnveilingOpportunites'
import InvestInTheFuture from '../components/InvestInTheFuture'
import Listedbytype from '../components/listedbytype' // Importe o novo componente
import InvestmentCard from '../components/FullOffer'
import CardToken from '../components/Card-Tokens'
import GetEarlyAcessButton from '../components/GetEarlyAcessButton'
import LPNavBar from '../components/LPNavBar'
import ModalLegalInformation from '../components/Modal-Legal-Information'

function LP() {
  const [primaryInvestments, setPrimaryInvestments] = useState([])
  const [cards, setCards] = useState([])

  useEffect(() => {
    fetch(
      'https://0g6o56vy4j.execute-api.us-east-2.amazonaws.com/tokens.json?page=1&per_page=10&isprimaryoffer=true'
    )
      .then((response) => response.json())
      .then((data) => {
        const investments = Object.keys(data).map((key) => data[key])
        setPrimaryInvestments(investments)
      })
      .catch((error) => console.error('Error fetching data:', error))

    fetch('/lpcards.json')
      .then((response) => response.json())
      .then((data) => setCards(data))
      .catch((error) => console.error('Error fetching cards data:', error))
  }, [])

  if (primaryInvestments.length === 0 || cards.length === 0) {
    return <div>Loading...</div>
  }

  return (
    <>
      <ModalLegalInformation />
      <LPNavBar />
      <MainText />
      {/* <BuyTokensButton /> */}
      {/* <PrimaryOfferings /> */}

      {/* <div className="card-container">
        {primaryInvestments.map((investment, index) => (
          <CardToken key={index} investment={investment} />
        ))}
      </div> */}

      {/* <div>
        <Listedbytype /> 
      </div> */}

      <UnveilingOpportunites />

      {/* Aqui é onde os cards serão mostrados */}
      <div className="lpcard-container">
        {cards.map((card, index) => (
          <CardLP key={index} title={card['card-title']} text={card['card-text']} />
        ))}
      </div>
      {/* Até aqui! */}

      <div className="align">
        <div className="Maintext">Invest in the future with Speck Finance</div>
        <div className="align SecondaryText">
          Simplify your investment strategies, diversify your portfolio, and enjoy enhanced
          accessibility.
          <div></div>Click and start today to transform your financial potential!
        </div>
        <div className="addMargin">
          <GetEarlyAcessButton></GetEarlyAcessButton>
        </div>
      </div>
      <FooterComponent />
    </>
  )
}

export default LP
