import React from 'react';

const RedTag = () => {
  return (
    <div
      style={{
        backgroundColor: 'red',
        color: 'white',
        padding: '10px',
        fontSize: '16px',
        fontWeight: 'bold',
        borderRadius: '5px',
        position: 'fixed',
        top: '10px',
        right: '10px',
        zIndex: 1000,
      }}
    >
      WARNING: This is a demo website. All content is fictional and for demonstration purposes only.
    </div>
  );
};

export default RedTag;