import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import './Header.css'
import logo from '../assets/img/mainLogo.svg'
import icon from '../assets/img/Icon.svg'
import arrowdown from '../assets/img/arrowdown.svg'
import RedTag from '../components/RedTag'

// import Stack from 'react-bootstrap/Stack';
const SpeckNavBar = () => {
  return (
    <>
      <Navbar expand="lg" className="custom-navbar" href="/home">
        <Navbar.Brand className="brandName" href="/home">
          <img src={logo} alt="logo" /> speck<d className="Copyright">©</d>
        </Navbar.Brand>
        <Navbar.Toggle className="dropDownMenu" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto navbarOptions">
            <Nav.Link href="/home">Home</Nav.Link>
            <Nav.Link href="/about">About Us</Nav.Link>
            <Nav.Link href="/Offers">Buy Tokens</Nav.Link>
          </Nav>
          <Nav className="ms-auto">
            <Nav.Link className="p-2 custom-margin">
              <img src={icon} alt="globe" />
              EN-US
              <img src={arrowdown} alt="arrowdown" />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <RedTag />
      </Navbar>
    </>
  )
}
export { SpeckNavBar }
